<template>
    <v-card flat>
        <v-card-title>
            <span class="secondary--text font-weight-bold">Organization Settings</span>
        </v-card-title>
        <v-card-text>
            <v-tabs v-model="tab">
                <v-tab>General settings</v-tab>
                <v-tab>Secrets</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-card flat>
                        <v-card-title>
                            <span class="secondary--text font-weight-bold">General settings</span>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <h3>Features enabled here will be available for all spaces created in this organization.</h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col v-for="(feature, index) in features" :key="index" cols="12" sm="6" md="4" xl="3">
                                    <org-feature-status :feature="feature" />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <the-secrets-listing :level="3" />
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
    </v-card>
</template>

<script>
import eventBus from '@/components/EventBus'
import OrgFeatureStatus from '@/components/OrgFeatureStatus.vue'
import { mapState } from 'vuex'

const TheSecretsListing = () => import('@/components/TheSecretsListing.vue')

const featureProperties = {
    loading: false,
    enabled: false,
    canEnable: false,
    disabledReason: '',
    error: '',
    fn: () => {}
}

export default {
    components: { OrgFeatureStatus, TheSecretsListing },
    computed: {
        ...mapState(['currentOrgData'])
    },
    data() {
        return {
            switchingFeature: '',
            features: {
                vimeo: {
                    ...featureProperties,
                    label: 'Video Library',
                    description: 'Upload videos to make your content more interactive on Nuvolos.'
                },
                hpc: {
                    ...featureProperties,
                    label: 'High Performance Computing',
                    description: 'Scale your application to dedicated high-performance compute nodes.'
                },
                tables: {
                    ...featureProperties,
                    label: 'Database Tables',
                    description: 'Use the Scientific Data Warehouse to work with massive amounts of tabular data.'
                }
            },
            tab: 0
        }
    },
    created() {
        eventBus.$on('global-dialog-accept', this.enableFeature)
        Object.keys(this.features).forEach(this.fetchStatusOfFeature)
    },
    beforeDestroy() {
        eventBus.$off('global-dialog-accept')
    },
    methods: {
        confirmFeatureSwitch(feature) {
            return async newState => {
                this.switchingFeature = feature
                const dialogTitle = `${newState ? 'Enabling' : 'Disabling'} ${this.features[feature].label}`
                const dialogText = newState
                    ? `Once this feature is enabled, Space Administrators will be able to enable it in their spaces individually`
                    : `By turning off this feature, Space Administrators won't be able to change their space's feature flag anymore. This means, spaces which already have this feature enabled will remain enabled, but the feature cannot be enabled anymore for other spaces.`
                this.$store.dispatch('showGlobalDialog', {
                    dialogTitle,
                    dialogText,
                    dialogAction: ['cancel', 'accept']
                })
            }
        },
        async enableFeature() {
            const feature = this.switchingFeature
            const { oid } = this.$route.params
            const f = this.features[feature]
            f.loading = true

            try {
                const { data } = await this.$axios.patch(`orgs/${oid}`, {
                    [`${feature}_enabled`]: !f.enabled
                })
                f.loading = false
                this.$store.commit('setCurrentOrgData', data)
            } catch (error) {
                console.error(error)
                this.$store.dispatch('showSnackBar', {
                    snackBarText: `Failed to ${this.features[feature].enabled ? 'disable' : 'enable'} ${this.features[
                        feature
                    ].label.toLowerCase()}, please try again later!`,
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
                f.loading = false
            }
        },
        fetchStatusOfFeature(feature) {
            const f = this.features[feature]
            f.loading = true
            f.fn = this.confirmFeatureSwitch(feature)
            this.$axios
                .get(`/orgs/${this.$route.params.oid}/can_enable_${feature}`)
                .then(({ data }) => {
                    if (this.currentOrgData) f.enabled = this.currentOrgData[`${feature}_enabled`]
                    f.canEnable = data[`can_enable_${feature}`]
                    f.disabledReason = data.reason
                    f.loading = false
                })
                .catch(error => {
                    f.loading = false
                    f.error = error.message
                })
        }
    },
    watch: {
        currentOrgData(org) {
            for (const feature in this.features) {
                this.features[feature].enabled = org[`${feature}_enabled`]
            }
        }
    }
}
</script>
